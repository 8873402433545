<template>
    <div class="custom-font" style="width: 100vw;height: 100vh;background-color: white;overflow-y: auto">
        <div class="appform">
            <div
                style="width: 100%;padding: 0px 20px;box-sizing: border-box;margin-top: 0px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                <img src="../../assets/ipsyo_form_logo.png" style="width: 267px;height: auto;object-fit: cover;">
                <p
                    style=" color: #C04B64; font-size: 30px; font-weight: bold;text-align: center;margin-top: 20px;line-height: 40px;">
                    International Psychology Olympiad <br>Student Registration</p>
            </div>
            <div style="color: #525252;font-size: 14px;line-height: 24px;margin-top: 20px;text-align: center;">
                <p> The Global Platform and Arena for Psychology Enthusiasts in High Schools</p>
                <p style="margin-top: 20px;">The International Psychology Olympiad (IPsyO) is an annual competition for
                    high school students. In bringing together gifted students, the IPsyO competition challenges and
                    stimulates these students to expand their talents and potential to become the next generation of
                    leaders in mind and behavior science.</p>
                <p style="margin-top: 20px;">Web: www.ipsyo.org <br>
                    Email: contact@ipsyo.org</p>
            </div>

            <el-form :model="form" :rules="formRules" ref="form"
                style="margin-top: 20px;width: 100%;padding: 0px 20px;box-sizing: border-box;" label-position="top">

                <div class="flex-row-center">
                    <el-form-item label="Country/Region" prop="student_country" class="flex-1">
                        <el-select v-model="form.student_country" style="width: 400px;" placeholder="Please select">
                                    <el-option v-for="(country, index) in countryList" :key="index"
                                        :label="country.country_en" :value="country.country_en"></el-option>
                                </el-select>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="State/Province" prop="student_province" class="flex-1">
                        <el-input v-model="form.student_province" placeholder="Please input"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="School Name" prop="student_school">
                    <el-input v-model="form.student_school" placeholder="Please input"></el-input>
                </el-form-item>

                <!-- <div class="flex-row-center">
                    <el-form-item label="School Name in English" prop="student_schoolEN" class="flex-1">
                        <el-input v-model="form.student_schoolEN" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="Student's name in Native Language" prop="student_name" class="flex-1">
                        <el-input v-model="form.student_name" placeholder="Please input"></el-input>
                    </el-form-item>
                </div> -->

                <div class="flex-row-center">
                    <el-form-item label="Student’s First Name in English on the Passport"
                        prop="student_firstName_pinyin" class="flex-1">
                        <el-input v-model="form.student_firstName_pinyin" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="Student’s Last Name in English on the Passport" prop="student_lastName_pinyin"
                        class="flex-1">
                        <el-input v-model="form.student_lastName_pinyin" placeholder="Please input"></el-input>
                    </el-form-item>
                </div>

                <div class="flex-row-center">
                    <el-form-item label="Gender" prop="student_gender" class="flex-1">
                        <el-select v-model="form.student_gender" placeholder="Please select">
                            <el-option value="Male">Male</el-option>
                            <el-option value="Female">Female</el-option>
                        </el-select>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="Grade" prop="student_grade" class="flex-1">
                        <el-select v-model="form.student_grade" placeholder="Please select">
                            <el-option value="9">9</el-option>
                            <el-option value="10">10</el-option>
                            <el-option value="11">11</el-option>
                            <el-option value="12">12</el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="flex-row-center">
                    <el-form-item label="Student's Email" prop="student_email" class="flex-1">
                        <el-input v-model="form.student_email" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="Student's Contact Number (Mobile)" class="flex-1">
                        <el-input v-model="form.student_mobile" placeholder="Please input"></el-input>
                    </el-form-item>
                </div>
                <div class="flex-row-center">
                    <el-form-item label="Guardian Contact Number (Mobile)" class="flex-1">
                        <el-input v-model="form.student_guardian_first_mobile" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="Guardian Email" class="flex-1">
                        <el-input v-model="form.student_guardian_first_email" placeholder="Please input"></el-input>
                    </el-form-item>
                </div>
                <!-- 
                <el-form-item label="How do you know about International Psychology Olympiad?" prop="know_source">
                    <el-input v-model="form.know_source" placeholder="Please input"></el-input>
                </el-form-item>
                <p style="color: #666;font-size: 16px;margin-top: 10px;">Your Adviser's Information
                </p> -->

                <!-- <div class="flex-row-center">
                    <el-form-item label="Adviser Name" prop="adviser_name" class="flex-1">
                        <el-input v-model="form.adviser_name" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <el-form-item label="Adviser Position" prop="adviser_title" class="flex-1">
                        <el-input v-model="form.adviser_title" placeholder="Please input"></el-input>
                    </el-form-item>
                </div> -->

                <!-- <div class="flex-row-center">
                    <el-form-item label="Adviser Email" prop="adviser_email" class="flex-1">
                        <el-input v-model="form.adviser_email" placeholder="Please input"></el-input>
                    </el-form-item>
                    <div class="empty-divider"></div>
                    <div class="flex-1"></div>
                </div> -->
            </el-form>

            <div class="row" style="margin-bottom: 50px">
                <span class="submit" @click="submit">Submit</span>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getCountries,
    saveStudentForm
} from '../../api/ipsye'
export default ({
    name: 'index',
    data() {
        return {
            form: {
                student_country: '',
                student_school: '',
                student_province: '',
                student_schoolEN: '',
                student_name: '',
                student_lastName_pinyin: '',
                student_firstName_pinyin: '',
                student_gender: '',
                student_grade: '',
                student_email: '',
                student_mobile: '',
                student_guardian_first_mobile: '',
                student_guardian_first_email: '',
                know_source: '',

                adviser_name: '',
                adviser_title: '',
                adviser_email: '',

            },
            formRules: {
                student_school: [{ required: true, message: "Please input", trigger: "blur" }],
                student_country: [{ required: true, message: "Please select", trigger: "blur" }],
                student_province: [{ required: true, message: "Please input", trigger: "blur" }],
                student_schoolEN: [{ required: true, message: "Please input", trigger: "blur" }],
                student_name: [{ required: true, message: "Please input", trigger: "blur" }],
                student_lastName_pinyin: [{ required: true, message: "Please input", trigger: "blur" }],
                student_firstName_pinyin: [{ required: true, message: "Please input", trigger: "blur" }],
                student_gender: [{ required: true, message: "Please select", trigger: "blur" }],
                student_grade: [{ required: true, message: "Please select", trigger: "blur" }],
                student_email: [{ required: true, message: "Please input", trigger: "blur" }],
                student_mobile: [{ required: true, message: "Please input", trigger: "blur" }],
                student_guardian_first_mobile: [{ required: true, message: "Please input", trigger: "blur" }],
                student_guardian_first_email: [{ required: true, message: "Please input", trigger: "blur" }],
                know_source: [{ required: true, message: "Please input", trigger: "blur" }],
            },
            countryList:[]
        }
    },
    mounted(){
        this.requestCountryList()
    },
    methods: {

        requestCountryList() {
            getCountries().then((res) => {
                this.countryList = res.data
            })
        },

        submit() {
            this.$refs.form.validate((val) => {
                if (val) {
                    saveStudentForm(this.form).then((res) => {
                        if (res) {
                            this.$confirm("You'll get a confirmation email for successful registration. Contact us if it's not in your inbox.", 'Submit Successful', {
                                showCancelButton: false,
                                confirmButtonText: 'OK',
                                confirmButtonClass: "user-confirm",
                                type: 'success'
                            })
                        }
                    });
                }
            });
        }
    }
})
</script>
<style>
.el-message-box .el-message-box__btns .user-confirm {
    background-color: #C04B64;
    border-color: #C04B64;
}
</style>
<style scoped lang="scss">
.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;

    .flex-1 {
        flex: 1;
    }

    .empty-divider {
        width: 30px;

    }
}



.custom-font {
    font-family: 'Century Gothic';
}

.appform {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: white;
    width: 840px;
    box-shadow: 0 2px 5px 1px rgba(124, 124, 124, 0.2);
    font-family: "DDINAlternateRegular";
    overflow: hidden;
    position: relative;

    .row {
        margin: 0 auto;
        justify-content: space-between;
        margin-bottom: 20px;
        display: flex;
    }

    .el-checkbox-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .el-radio-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .submit {
        padding: 10px 70px;
        background: #C04B64;
        color: #fff;
        cursor: pointer;
    }
}


/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-color: #C04B64;
}

/deep/ .el-input__inner:hover {
    border-color: #C04B64;
}

/deep/ .el-input__inner:focus {
    border-color: #C04B64;
}

/deep/ .el-textarea__inner {
    border-color: #C04B64;
}

/deep/ .el-textarea__inner:hover {
    border-color: #C04B64;
}

/deep/ .el-textarea__inner:focus {
    border-color: #C04B64;
}

/deep/ .el-form-item__label {
    font-size: 16px;
    color: #000000;
}

/deep/ .el-checkbox__label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
}

/deep/ .el-radio__label {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #C04B64;
    border-color: #C04B64;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #C04B64;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    background-color: #C04B64;
    border-color: #C04B64;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #C04B64;
}
</style>